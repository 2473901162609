<template>
  <div class="main">
    <h3>填写发票信息</h3>
    <el-form :model="form" ref="form" :rules="rules">
      <div class="type">
        <div style="color: #6e6e6e; margin-right: 20px">抬头类型</div>
        <van-radio-group v-model="form.invoiceType" direction="horizontal">
          <van-radio :name="0">单位</van-radio>
          <van-radio :name="1">个人/非企业性单位</van-radio>
        </van-radio-group>
      </div>
      <div class="input-title">
        <div>发票抬头 <span style="color: #ff706b">*</span></div>
        <div style="color: #ff706b">必填</div>
      </div>
      <el-form-item prop="content">
        <input type="text" class="text-input" v-model="form.content" />
      </el-form-item>
      <div class="input-title" v-if="form.invoiceType == 0">
        <div>税号 <span style="color: #ff706b">*</span></div>
        <div style="color: #ff706b">必填</div>
      </div>
      <el-form-item prop="ghdwsbh" v-if="form.invoiceType == 0">
        <input type="text" class="text-input" v-model="form.ghdwsbh" />
      </el-form-item>
      <div class="input-title">
        <div>电话 <span style="color: #ff706b">*</span></div>
        <div style="color: #ff706b">必填</div>
      </div>
      <el-form-item prop="phone">
        <input type="text" class="text-input" v-model="form.phone" />
      </el-form-item>
      <div class="input-title">
        <div>邮箱地址 <span style="color: #ff706b">*</span></div>
        <div style="color: #ff706b">必填</div>
      </div>
      <el-form-item prop="email">
        <input type="text" class="text-input" v-model="form.email" />
      </el-form-item>
      <div class="input-title" v-if="form.invoiceType == 0">
        <div>账号</div>
      </div>
      <el-form-item prop="ghdwyhzh" v-if="form.invoiceType == 0">
        <input type="text" class="text-input" v-model="form.ghdwyhzh" />
      </el-form-item>
    </el-form>
    <div class="submit">
      <van-button round type="info" style="width: 100%" @click="invoiceSumbit">确定</van-button>
    </div>
    <div class="contact" v-if="serviceTel !== null">
      <el-link type="primary" @click="handleContact">如有疑问请联系客服</el-link>
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";

export default {
  name: "Invoice",
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      form: {
        invoiceType: 0,
        content: null,
        ghdwsbh: null,
        phone: null,
        email: null,
        ghdwyhzh: null,
        orderPart: 0,
        orderIds: null,
        plateNo: this.$route.query.plateNo,
        appId: localStorage.getItem("appId"),
      },
      rules: {
        content: [{ required: true, message: "请填写发票抬头", trigger: "blur" }],
        ghdwsbh: [{ required: true, message: "请填写税号", trigger: "blur" }],
        phone: [{ required: true, message: "请填写电话号码", trigger: "blur" }],
        email: [{ required: true, message: "请填写邮箱地址", trigger: "blur" }],
      },
      serviceTel: null,
      domain: process.env.VUE_APP_DOMAIN,
    };
  },
  mounted() {
    let appId = this.form.appId;
    // 查询客服电话
    this.$axios
      .post(`${this.domain}/applet/customer-service-number`, {
        appId,
      })
      .then((res) => {
        if (res.data.data !== null) {
          this.serviceTel = res.data.data[0].phoneNumber;
        }
      });
    // 查询停车订单ID
    this.$axios
      .post(`${this.domain}/applet/findInvoicingOrderAvailable`, {
        parkIds: this.$route.query.orderIds,
      })
      .then((res) => {
        this.form.orderIds = res.data.data;
      });
  },
  methods: {
    invoiceSumbit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$toast.loading({
            duration: 0,
            message: "开票中...",
            forbidClick: true,
            loadingType: "spinner",
          });
          this.$axios
            .post(`${this.domain}/applet/appletInvoices`, this.form)
            .then((res) => {
              this.$toast.clear();
              if (res.data.code === 200) {
                Dialog.confirm({
                  message: `开票成功，已发送到邮箱地址`,
                })
                  .then(() => {
                    WeixinJSBridge.call("closeWindow");
                  })
                  .catch(() => {
                    // on cancel
                  });
              } else {
                this.$toast.fail(`${res.data.msg}`);
              }
            })
            .catch((err) => {
              this.$toast.fail(`开票失败`);
              this.$toast.clear();
            });
        } else {
          this.$toast.fail("请填写参数");
        }
      });
    },
    handleContact() {
      window.location.href = `tel:${this.serviceTel}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  padding: 20px;

  .type {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 20px;
  }

  h3 {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    font-weight: none;
    color: #6e6e6e;
    font-size: 16px;
  }

  .input-title {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    color: #838282;
  }

  .text-input {
    width: 100%;
    height: 40px;
    font-size: 16px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #d7d7d7;
  }

  .submit {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .contact {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
